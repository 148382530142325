@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600;700;800;900&display=swap');


/*  

font-family: "Gilroy-Bold";
font-family: 'Jost', sans-serif;


 */

:root {
    --default-orange: #FF4200;
    --default-blue: #003e82;
    --default-black: #191919;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    font-family: 'Jost', sans-serif;
}

.accordion-button:focus {
    outline: none !important;
    box-shadow: none !important;
}

.nav-link {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;

    color: var(--default-black);
}

.nav-item {
    padding: 15px;
}

.nav-item.active .nav-link {
    color: black;
    font-weight: 700 !important;
    position: sticky;

}

.nav-item.active .nav-link::before {
    content: "";
    position: absolute;
    left: 9px;
    bottom: 0;
    height: 10px;
    width: 30px;
    border-bottom: 2px solid var(--default-orange);
}

.text-orange {
    color: var(--default-orange) !important;
}

h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 55px;
    line-height: 62px;
}

.triangle {
    width: 0;
    height: 0;
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
    border-bottom: 100px solid white;
}

p {
    font-weight: 400;
    font-size: 20px;
    line-height: 34px;
}

.gmail-btn {
    border-radius: 27.5px;
    padding: 7px;
    background-color: white;
}

.gmail-btn .fa-google {
    margin-right: 0.5rem;
}

.float-left {
    float: left;
}



.inputhero {
    padding: 15px !important;
    font-family: 'Jost', sans-serif;
}

.bg-transparent {
    background-color: transparent;
}

.phone-filed-transparent .input-group-text {
    padding: 15px !important;
    position: relative;
}

.btn-primary {
    background-color: var(--default-orange);
    border: 1px solid var(--default-orange);
    transition: 0.3s;
    color: #fff !important;
}

.btn-primary:hover {
    color: var(--default-orange) !important;
    background-color: #fff;
    border: 1px solid var(--default-orange);
}

.left-banner-content .btn-hero {
    padding: 14px 80px !important;
    font-family: 'Jost', sans-serif;
    font-size: 18px;
    letter-spacing: 0.5px;
    margin-top: 18px;
}

.section-title h5 {
    font-family: 'Jost', sans-serif;
    font-size: 20px;
    font-weight: 500;
}

.section-title h2 {
    font-family: 'Jost', sans-serif;
    font-size: 55px;
    font-weight: 700;
    position: relative;
    z-index: 1;
    display: inline-block;
    text-decoration: underline;
    text-decoration-color: #ffd9cc;
    text-decoration-thickness: 10px;
    line-height: 70px;
    -webkit-text-underline-offset: 0.0rem;
    text-underline-offset: 0.9rem;
}

/* .section-title h2:after {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    bottom: 10px;
    width: 100%;
    height: 10px;
    background: #ffd9cc;
    z-index: -1;
} */
.title {
    text-align: center;
    overflow: hidden;
    padding: 2px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 0;
}

.title span {
    display: inline-block;
    position: relative;
    padding: 15px;
}

.title span:before,
.title span:after {
    content: '';
    display: block;
    position: absolute;
    width: 180px;
    border-bottom: 2px solid #ffffff59;

    height: 3px;
    top: 50%;
    margin-top: -3px;
}

.title span:before {
    right: 100%;
}

.title span:after {
    left: 100%;
}

.spaceContainer {
    padding: 80px 0px;
}

.work-card-col h5 {
    font-family: 'Jost', sans-serif;
    font-size: 24px;
    line-height: 36px;
}

.work-card-col p {
    font-family: 'Jost', sans-serif;
    font-size: 20px;
    line-height: 32px;
}

.work-card-col a.learnmore {
    font-family: 'Jost', sans-serif;
    font-size: 20px;
    line-height: 32px;
    text-decoration: none;
}

h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    color: #191919;
}

.pcs {
    font-size: 18px;
    line-height: 30px;
}

h5 {
    font-weight: 700;
    font-size: 18px;
    color: #191919;
}

.b-none {
    border: none !important;
}

.left-banner-content h2 {
    font-family: 'Jost', sans-serif;
    font-weight: 700;
    font-size: 32px;
    line-height: 1.2em;
}

.left-banner-content h3 {
    font-family: 'Jost', sans-serif;
    font-weight: 500;
    font-size: 62px;
}

.left-banner-content p {
    font-family: 'Jost', sans-serif;
    font-weight: 300;
    font-size: 18px;
    line-height: 1.4em;
}

.termsagree {
    font-family: 'Jost', sans-serif;
    font-weight: 300;
    font-size: 12px;
    line-height: 1.4em;
    margin-top: 10px;
    display: block;
}

.requiredPhone {
    font-family: 'Jost', sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 1.4em;
    margin-top: 5px;
    display: block;
}

.left-banner-content .gmail-btn {
    margin: 20px 0;
    padding: 10px;
    font-size: 18px;
    font-family: 'Jost', sans-serif;
    font-weight: 500;
    color: #003E82;
    line-height: 30px;
    width: 100%;
}

.blog-card .learnmore {
    font-family: 'Jost', sans-serif;
    font-weight: 600;
    font-size: 17px;
    text-decoration: none;
    text-align: left;
    color: #003E82;
}

.newsletter-section .section-title h2:after {
    width: 40%;
    margin: 0 auto;
}

.newsletter-btn {
    font-family: 'Jost', sans-serif;
    font-weight: 600;
    font-size: 17px;
}

.newsletter-section {
    padding: 100px 0;
}

.cta {
    padding: 40px 70px !important;
    background: #003E82;
    border-radius: 150px;
}

.cta h4 {
    font-size: 22px;
}

.cta p {
    font-size: 18px;
    font-weight: 300;
}

.hammer {
    margin-top: -46px;
    position: absolute;
    width: 148px;
}

.accordion-item {
    border: none !important;
}

.accordion-button:not(.collapsed) {
    background-color: #fff !important;
    padding: 4px 10px 4px 20px !important;
    font-weight: 700;
    color: var(--default-orange) !important;
}

.accordion-button {
    /* padding: 6px 18px; */
    /* border-top: 1px dashed #191919; */
    font-weight: 700;
}

.accordion-item:first-of-type .accordion-button {
    border: none !important;
}

.blog-card {
    border: none !important;
    border-radius: 25px;
    background: #FFFFFF;
    box-shadow: 20px 20px 80px rgba(112, 144, 176, 0.08);
}

.blog-card .card-body {
    padding: 35px 25px !important;
}

.blog-time {
    font-family: 'Jost', sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    letter-spacing: 0.02em;
    opacity: 0.6;
    color: #191919;
}

.blog-card-img {
    height: 180px;
    object-fit: cover;
}

.blog-card .card-title {
    font-size: 26px;
    margin: 20px 0;
}

.blog-tag {
    font-family: 'Jost', sans-serif;
    font-weight: 400;
    border-radius: 9px;
    font-size: 13px;
    padding: 4px 8px;
    color: #fff;
    background-color: var(--default-orange) !important;
}

.space-between {
    justify-content: space-between;
}

.input-subs {
    background: #FFFFFF;
    padding: 10px;
    border-radius: 10px;
}

.btn-let-subs {
    font-size: 17px;
    padding: 7px 40px !important;
}

.btn-subsgrp .input-group-text {
    background-color: #fff;
    border: none;
    padding: 8px;
}

.subscribeInputContainer .form-control {
    border: none !important;
    background-color: #fff;
}

.subscribeInputContainer .form-control:focus {
    outline: none;
    box-shadow: none;
}

.subscribeInputContainer span.input-group-text {
    background-color: transparent !important;
    border: none !important;
    margin: auto;

}

.subscribeInputContainer .iconsub {
    border-right: 1px solid #191919 !important;
    border-radius: 0;
}

.subscribeInputContainer {
    border-radius: 5px;
}

.p-90 {
    padding: 130px 40px;
}

.heroimg {
    height: 508px;
    margin-bottom: -41px;

}

.polygon {
    -webkit-clip-path: polygon(100% 4%, 0% 100%, 100% 100%);
    background-color: #fff;
    clip-path: polygon(100% 4%, 0% 100%, 100% 100%);
    background-color: #ffffff;
    clip-path: polygon(213% -29%, 0% 100%, 100% 100%);
    margin-top: -208px;
    z-index: 9999999;
    height: 260px;
    clip-path: polygon(141% -27%, 6% 100%, 100% 100%);
    margin-top: -222px;
    clip-path: polygon(151% -26%, 6% 100%, 100% 100%);
    margin-top: -251px;
}


.customfooter .nav-item {
    padding: 0px;
    text-align: left;
    color: #fff;
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 17px;
    line-height: 29px;
    letter-spacing: 0.05em;
}

.customfooter .nav-item a,
.customfooter h5 {
    font-family: 'Jost', sans-serif;
    text-align: left;
    font-size: 17px !important;
    color: #fff !important;
    line-height: 32px;
    font-weight: 300;
}

.contact-info .d-flex {
    display: flex !important;
    gap: 10px;
}

.customfooter h5 {
    font-weight: 500 !important;
}

.googleplay,
.applestore {
    font-family: 'Jost', sans-serif;
    text-decoration: none;
    padding: 14px 22px;
    background: #FFFFFF;
    border-radius: 10px;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.05em;
    text-decoration: none;
    color: #003E82 !important;
    width: 100%;
    max-width: 100%;
    display: inline-block;
}


.policty li {
    color: #fff !important;
    display: inline;
    padding: 0 2px 0px 0;
    border-bottom: 1px solid #fff;
}

.policty li a {
    font-family: 'Jost', sans-serif;
    color: #fff !important;
    text-decoration: none !important;
    opacity: 0.7;
}

.policty li i {
    font-size: 12px;
}

.googleplay a,
.applestore a {}

.googleplay i,
.applestore i {
    font-size: 16px;
    color: var(--default-orange);
}

.navbar {
    padding: 25px 0;
}

#headercollpase .nav-link {
    text-align: left;
    font-family: 'Jost', sans-serif;
}

#headercollpase .btn-hero {
    padding: 10px 25px !important;
    font-weight: 600;
}

.navbar-nav {
    align-items: center;
}

#headercollpase .btn-hero i {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    vertical-align: middle;
    margin-right: 4px;
    margin-top: -2px;
}

.sociallinks li {
    display: inline;
}

.sociallinks {
    margin-left: auto;
}

.sociallinks .icon {
    display: inline-block;
    color: #fff;
}

.underlineBack {
    padding: 4px 152px;
    background-color: #ff420024;
    position: absolute;
    z-index: -999;
    margin-top: 41px;
    margin-left: 3px;
}

.polygonfooter {
    -webkit-clip-path: polygon(100% 77%, 0% 100%, 100% 100%);
    clip-path: polygon(127% 77%, 0% 100%, 100% 100%);
    background-color: #003e82;
    margin-top: 0px;
    -webkit-clip-path: polygon(100% 77%, 0% 100%, 100% 100%);
    clip-path: polygon(100% 44%, 0% 100%, 100% 100%);
    z-index: 9999999;
    height: 153px;
    margin-bottom: -5px;
    -webkit-cli--path: polygon(105% 33%, 0% 102%, 150% 101%);
    width: 96%;
    clip-path: polygon(114% 51%, -5% 99%, 134% 403%);
    margin-left: auto;
}

.herosection {
    background-color: var(--default-blue);
    border-radius: 50px;
}


.footercopyright {
    font-size: 17px;
    padding-bottom: 0px !important;
    margin-bottom: 0;
    opacity: 0.9;
    line-height: 1.5em;
}

.contact-info .nav-item a {
    font-weight: 400 !important;
    font-family: 'Jost', sans-serif;
}

.footer-row {
    background: url(../public/images/footer-bg.png);
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    padding: 72px 32px 40px 32px !important;
}

.footer-container h5 {
    font-weight: 600;
    font-size: 18px;
}

.footer-nav .nav-item {
    padding: 0px;
}

.footer-nav .nav-item a {
    color: #fff !important;
    text-align: left;
    font-size: 17px;
    font-family: 'Jost', sans-serif;
    font-weight: 300;
    opacity: 0.9;
}

.footer-nav .nav-item a:hover {
    opacity: 1;
}

.contact-info a {
    color: #fff !important;
    text-align: left;
}

.footer-logo {
    margin-top: -55px;
}

.footer-container {
    padding-top: 50px;
}

.border-top-footer {
    border-top: 1px #2369b5 solid;
}

.blog-grid .blog-card-img {
    height: 450px;
    object-fit: cover;
    border-radius: 30px 30px 0 0;
}

.blog-grid {
    margin-bottom: 40px;
}

.blog-grid .learnmore {
    background: #F7F6F9;
    padding: 13px 30px;
    border-radius: 5px;
    color: #191919;
    margin-top: 5px;
    display: inline-block;
}

.blog-grid .learnmore:hover {
    background: #FF4200;
    color: #fff;
}


/* login */

.myform {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    padding: 3rem;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #F5F5FB;
    background-clip: padding-box;
    border-radius: 1.1rem;
    outline: 0;
    max-width: 500px;
}

.tx-tfm {
    text-transform: uppercase;
}

.mybtn {
    border-radius: 50px;
}

.login-or {
    position: relative;
    color: #191919;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 26px;
    padding-bottom: 25px;
}

.span-or {
    font-size: 18px;
    font-family: 'Jost', sans-serif;
    display: block;
    position: absolute;
    left: 50%;
    top: 12px;
    margin-left: -25px;
    background-color: #F5F5FB;
    width: 50px;
    text-align: center;
}

.hr-or {
    height: 1px;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.google {
    color: #666;
    width: 100%;
    height: 40px;
    text-align: center;
    outline: none;
    border: 1px solid lightgrey;
}

form .error {
    color: #ff0000;
}

.dark-back {
    background: #F5F5FB;
    min-height: 100vh;
    Max-height: 100%;
}

.dark-back1 {
    background: #F5F5FB;
}

.mylogin-form h3 {
    color: #EC5329;
    font-size: 36px;
    margin-top: 40px;
    margin-bottom: 30px;
}

.login-btn {
    font-size: 18px;
    font-family: 'Jost', sans-serif;
    font-weight: 500;
    padding: 14px 80px;
    margin-top: 25px;
}

.login-gmail-btn {
    background: #003E82;
    border-radius: 10px;
    font-size: 18px;
    font-family: 'Jost', sans-serif;
    font-weight: 500;
    color: #fff;
    line-height: 40px;
    width: 100%;
}

.login-gmail-btn .float-left {
    background: #fff;
    width: 40px;
    height: 40px;
    border-radius: 7px;
}

.loging-footer-section {
    margin-top: 20px;
    padding: 20px 0;
}

.loging-footer-section p {
    font-size: 14px;
}

.footer-full {
    text-align: center;
}

.footer-full p {
    margin-bottom: 0px;
}

.otp-number {
    font-size: 18px;
    font-family: 'Jost', sans-serif;
    font-weight: 500;
    color: #003E82;
    line-height: 30px;
    text-align: center;
}

.otp-icon {
    display: inline-block;
    vertical-align: text-bottom;
}

.opt-box ul {
    padding: 0px;
    margin: 0px;
}

.opt-box ul li {
    display: inline-block;
    background: #F8F7FC;
    box-shadow: 0px 4px 0px #ebe8f9;
    border-radius: 50%;
    font-size: 18px;
    font-family: 'Jost', sans-serif;
    font-weight: 600;
    color: #525252;
    width: 45px;
    height: 45px;
    text-align: center;
    line-height: 50px;
    margin: 4px;
}

.opt-box {
    text-align: center;
    margin-top: 40px;
}

.recive-code {
    text-align: center;
    font-size: 18px;
    font-family: 'Jost', sans-serif;
    font-weight: 400;
    color: #838383;
}

.recive-code a {
    font-weight: 600;
    color: #003E82;
    text-decoration: none;
}

.recive-code {
    /* margin-top: 20px; */
    margin-left: 115px;
}

.dark-hedaer-container {
    display: block !important;
}

.dark-hedaer {
    background: #003E82;
    padding: 30px;
    border-radius: 60px;
}

.profile-info h3 {
    font-weight: 700;
}

.profile-details {
    margin-top: 15px;
}

.profile-details {
    display: flex;
    gap: 50px;
    -webkit-gap: 50px;
    justify-content: space-between;
    padding-right: 22px;
}

.profile-details-right img {
    width: 120px;
}

.profile-details-right img {
    width: 135px;
    border-radius: 10px;
}

.profile-details-right {
    position: relative;
}

.profile-details-right .profile-img-uplode {
    /*background: #FF4200;
    width: 30px;
    height: 30px;
    max-width: 30px;
    min-width: 30px;
    display: inline-block;
    border-radius: 50%;*/
    position: absolute;
    bottom: -7px;
    right: -4px;
    text-align: center;
}

.profile-details-right .profile-img-uplode img {
    width: 32px;
    height: 32px;
    border-radius: 0;
    position: absolute;
    top: 8px;
    right: 0px;
}

.profile-img-uplode input {
    width: 32px;
    height: 32px;
    background: transparent;
    opacity: 0;
    position: relative;
    z-index: 99;
}

.form-floating input {
    min-height: 52px;
    max-height: 52px;
    padding-left: 40px !important;
}

.form-floating select {
    min-height: 52px;
    max-height: 52px;
    padding-left: 40px !important;
    padding-top: 7px !important;
    padding-bottom: 0.125rem !important;
    font-family: 'Jost', sans-serif;
    font-weight: 400;
    color: #838383;
    font-size: 18px;
}

.form-inline .form-floating>label {
    top: -4px;
    font-size: 17px;
    font-family: 'Jost', sans-serif;
    font-weight: 400;
    color: #7c7c7c;
    padding-left: 40px;
}

.form-inline .form-icon {
    position: absolute;
    top: 14px;
    left: 15px;
    color: #a5a5a5;
}

.form-control:focus {
    outline: none;
    box-shadow: none;
}

.mylogin-form input {
    color: #444 !important;
}

.checkbox-row label {
    font-size: 17px;
    font-family: 'Jost', sans-serif;
    font-weight: 500;
}

.checkbox-col {
    display: flex;
    gap: 20px;
    margin-top: 10px;
}

.checkbox-col label {
    font-weight: 400;
}

.form-date-piker label {
    font-size: 17px;
    font-family: 'Jost', sans-serif;
    font-weight: 500;
    margin-bottom: 10px;
}

.form-date-piker input {
    min-height: 52px;
    max-height: 52px;
    font-size: 17px;
    font-family: 'Jost', sans-serif;
    font-weight: 500;
}

.form-date-piker .input-group-addon {
    background: #003E82;
    color: #fff;
    width: 48px;
    text-align: center;
    font-size: 22px;
    justify-content: center;
}

.profile-details-left h4 {
    font-weight: 600;
}

.submitButton {
    font-size: 17px;
    font-family: 'Jost', sans-serif;
    font-weight: 500;
}

.card-logo-col {
    background: #fff;
    border-radius: 25px;
    padding: 30px;
    margin-top: 20px;
}

.card-logo-col .media {
    display: flex;
    gap: 20px;
}

.card-logo-col .media img {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    object-fit: cover;
}

.card-logo-col .media {
    display: flex;
    gap: 20px;
    align-items: center;
    margin-top: 40px;
}

.card-logo-col .media h5 {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 7px;
}

.gradiant-col {
    background: #003e82;
    background: -moz-linear-gradient(left, #003e82 2%, #3c6b9f 100%);
    background: -webkit-linear-gradient(left, #003e82 2%, #3c6b9f 100%);
    background: linear-gradient(to right, #003e82 2%, #3c6b9f 100%);
    border-radius: 25px;
    padding: 30px;
    margin-top: 30px;
}

.gradiant-col .media {
    display: flex;
    gap: 40px;
}

.gradiant-col .media h3 {
    color: #fff;
    font-size: 22px;
    padding-top: 12px;
}

.gradiant-col .media img {
    width: 90px;
}

.bold-text {
    color: #FF4200;
    font-weight: 700;
}

.gradiant-col ul {
    padding: 0px;
}

.gradiant-col ul {
    margin-top: 20px;
}

.gradiant-col ul li {
    display: inline-block;
    color: #fff;
    font-size: 17px;
    font-family: 'Jost', sans-serif;
    font-weight: 400;
    opacity: 0.5;
    padding: 0 6px;
}

.list-icon-row .list-icon-col {
    background: #fff;
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 15px;
    gap: 15px;
    margin: 22px 0;
    position: relative;
}

.list-icon-row .list-icon-col h5 {
    font-size: 16px;
    margin: 0;
}

.list-icon-row .list-icon-col .list-icon {
    background: #003e822b;
    /* opacity: 0.1; */
    width: 52px;
    height: 53px;
    border-radius: 10px;
    text-align: center;
    padding: 14px;
}

.list-icon-row {
    margin-top: 30px;
}

.question-icon {
    position: absolute;
    right: 17px;
    opacity: 0.5;
}

.left-login-col {
    background: #2F2F2F;
    width: 55px;
    display: inline-block;
    height: 55px;
    border-radius: 7px;
    text-align: center;
    padding: 14px;
}

.left-login-bottom a {
    display: flex;
    align-items: center;
    gap: 15px;
    text-decoration: none;
}

.left-login-bottom h5 {
    font-size: 17px;
    margin: 0;
}

.left-login-bottom {
    margin-bottom: 35px;
}

.color-text {
    color: #FF4200;
}

.profile-right-col {
    padding-top: 20px;
}

.profile-right-col h3 {
    font-size: 22px;
}

.profile-right-col h4 {
    font-size: 36px;

}

.profile-right-col p {
    font-size: 18px;
}

.search-box p {
    font-size: 19px;
}

.search-box .form-floating {
    position: relative;
    font-size: 17px;
    font-family: 'Jost', sans-serif;
    font-weight: 400;
}

.search-box .form-floating .form-icon {
    position: absolute;
    top: 20px;
    left: 15px;
    color: #19191970;
}

.search-box input {
    font-size: 18px;
    font-family: 'Jost', sans-serif;
    font-weight: 400;
    color: #19191970;
    min-height: 60px;
    max-height: 60px;
    padding-left: 40px !important;
}

.search-box label {
    padding-left: 40px !important;
    font-size: 18px;
    font-family: 'Jost', sans-serif;
    font-weight: 400;
    color: #19191970;
}

.trading {
    margin-top: 40px;
}

.trading h5 {
    font-weight: 500;
    color: #8d8d8d;
    font-size: 18px;
}

.trading ul {
    padding: 0px;
    margin: 0px;
    margin-top: 15px;
}

.trading ul li {
    display: inline-block;
    margin: 3px;
}

.trading ul li a {
    background: rgba(255, 66, 0, 0.05);
    border-radius: 5px;
    color: #FF4200;
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
    padding: 7px 15px;
}

.Recent-Searches {
    margin-top: 50px;
}

.Recent-Searches h5 {
    font-weight: 500;
    color: #8d8d8d;
    font-size: 18px;
}

.Recent-Searches ul {
    padding: 0px;
    margin: 0px;
}

.Recent-Searches ul li {
    list-style-type: none;
    line-height: 36px;
}

.pharmacy-search {
    background: #fff;
    padding: 30px;
    border-radius: 15px;
}

.pharmacy-col {
    border: 3px solid #F8F7FC;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 10px;
}

.pharmacy-col h4 {
    font-size: 22px;
    font-weight: 600;
    margin-top: 10px;
}

.pharmacy-col p {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
}

.pharmacy-col button.btn {
    border: 3px solid #F8F7FC;
    border-radius: 10px;
    padding: 7px;
    width: 100%;
    font-family: 'Jost', sans-serif;
    font-weight: 400;
    color: #222;
}

.pharmacy-col:hover {
    background: #FF4200;
}

.pharmacy-col:hover img {
    filter: brightness(0) invert(1);
}

.pharmacy-col:hover h4,
.pharmacy-col:hover p {
    color: #fff;
}

.pharmacy-col:hover button.btn {
    background: #fff;
    color: #191919;
}

.popup-photo-box {
    background: #074385;
    width: 130px;
    height: 130px;
    text-align: center;
    padding: 40px;
    border-radius: 50%;
    margin-top: -95px !important;
    margin: 0 auto;
    /* margin-left: 148px; */
}

.modal.show .modal-dialog {
    margin-top: 0%;
}

.popup-fermacy h3 {
    text-align: center;
    margin-top: 15px;
}

.modal-content-col {
    padding: 20px;
    border-radius: 12px;
    background: #F8F7FC;
}

.modal-content-col h4 {
    color: #074385;
}

.popup-fermacy-footer {
    justify-content: space-between;
    border: none;
}

.popup-fermacy-footer button.btn {
    width: 47%;
    font-family: 'Jost', sans-serif;
    color: #222;
    font-size: 18px;
    font-weight: 500;
    padding: 12px;
}

.uplode-pic-row {
    padding: 35px;
    background: url(../public/images/uplode-bg.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.uplode-pic-row h3 {
    color: #003E82;
    font-weight: 700;
    font-size: 32px;
}

.uplode-pic-drop {
    background: #fff;
    display: inline-block;
    padding: 10px;
    border-radius: 15px;
    color: #ff4200a1;
    font-weight: 600;
}

.uplode-pic-drop .uplode-pic-drop-img {
    background: #ff42002e;
    display: inline-block;
    padding: 10px;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 28px;
    margin-right: 7px;
    font-family: 'Jost', sans-serif;
}

.uplode-box {
    background: #ff42002e;
    height: 72px;
    display: inline-block;
    width: 72px;
    text-align: center;
    border-radius: 50%;
    padding: 10px 0 0 0px;
}

.uplode-pic-drop-img {
    background: #fff;
    width: 100px;
    height: 100px;
    display: inline-block;
    text-align: center;
    border-radius: 20px;
    padding: 14px;
}

.drag-drop {
    background: #FFFFFF;
    border-radius: 10px;
    width: 50%;
    margin: 0 auto;
    padding: 30px;
    text-align: center;
    position: relative;
    z-index: 1;
    margin-top: -68px;
}

.drag-drop:after {
    position: absolute;
    content: "";
    left: 12px;
    right: 12px;
    bottom: 12px;
    top: 12px;
    border-radius: 10px;
    border: 2px #ddd dashed;
    z-index: -1;
}

.drag-drop h4 {
    font-size: 20px;
    font-weight: 600;
}

.drag-drop input.form-control[type=file] {
    width: 107px;
    overflow: hidden;
    margin: 0 auto;
}

.profile-2-col .container-dashbord {
    max-width: 100% !important;
    padding: 30px;
}

.image-uplode-media-col .media {
    display: flex;
    align-items: center;
    gap: 10px;
    -webkit-gap: 10px;
    word-break: break-all;
}

.image-uplode-media-col .image {
    width: 20%;
    min-width: 20%;
    max-width: 20%;
}

.image-uplode-media-col .image img {
    width: 100%;
}

.image-uplode-media-col .media h6 {
    margin-bottom: 0px;
    font-size: 13px;
}

.image-uplode-media-col .media .complete {
    color: #279955;
    font-family: 'Jost', sans-serif;
    line-height: 20px;
    font-size: 14px;
    display: flex;
    gap: 3px;
    margin-top: 5px;
}

.image-uplode-media-col .media .to-big {
    color: #FF0F00;
    font-family: 'Jost', sans-serif;
    line-height: 20px;
    font-size: 14px;
    display: flex;
    gap: 3px;
    margin-top: 5px;
}

.image-uplode-media-col {
    background: #fff;
    padding: 7px;
    border-radius: 7px;
    margin: 10px 0;
}

.image-uplode-bar {
    margin-top: 50px;
}

.image-uplode-media-col .progress {
    height: 5px;
    margin-top: 10px;
}

.uplode-image-btn {
    text-align: center;
    margin-top: 100px;
}

.uplode-image-btn button.btn {
    background: #FF4200;
    font-family: 'Jost', sans-serif;
    line-height: 20px;
    font-size: 17px;
    color: #fff;
    padding: 15px 80px;
    border-radius: 10px;
}

.uplode-image-btn button.btn:hover {
    background: #FF0F00;
}

.thank-you-box {
    width: 150px;
    display: inline-block;
    margin: 0 auto;
    text-align: center;
}

.thank-you-box img {
    width: 100%;
}

.popup-thank-you {
    text-align: center;
    padding: 40px 40px 10px 40px;
    margin: 0 auto;
}

.thank-you-row .modal-content {
    border-radius: 25px;
}

.thank-you-row .popup-fermacy-footer {
    justify-content: center;
    padding-bottom: 40px;
}

.pharmacy-col {
    position: relative;
}

.track-icon {
    position: absolute;
    right: 15px;
    display: flex;
    align-items: center;
    gap: 6px;
    top: 20px;
}

.track-icon h6 {
    color: #279955;
    margin: 0;
    font-size: 13px;
}

.pharmacy-col:hover .track-icon h6 {
    color: #fff;
}

.order-status-row {
    justify-content: start;
    gap: 15px;
}

.order-status-row .left-arows-icon {
    background: #fff;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 36px;
    border-radius: 10px;
}

.order-id h3 {
    font-size: 32px;
    color: #003E82;
    font-weight: 700;
}


.steps .step {
    display: flex;
    width: 100%;
    margin-bottom: 25px;
    align-items: center;
    gap: 12px;
}

.steps .step.completed h4 {
    font-weight: 600;
    margin-top: 0px !important;
    font-size: 17px !important;
    color: #222 !important;
}

.steps .step .step-icon-wrap {
    display: block;
    position: relative;
    width: 60px;
    height: 60px;
    text-align: center
}

.steps .step .step-icon-wrap::before,
.steps .step .step-icon-wrap::after {
    display: block;
    position: absolute;
    top: 48px;
    width: 2px;
    height: 49px;
    content: '';
    z-index: 1;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.steps .step .step-icon-wrap::before {
    left: 0
}

.steps .step .step-icon-wrap::after {
    right: 0
}

.steps .step .step-icon {
    display: inline-block;
    position: relative;
    width: 48px;
    height: 48px;
    border: 1px solid #e1e7ec;
    border-radius: 50%;
    background-color: #f5f5f5;
    color: #374250;
    font-size: 38px;
    line-height: 38px;
    z-index: 5;
}

.step-content-wrap h6 {
    color: #11111150;
    font-size: 14px;
    margin-top: 6px;
}

.steps .step .step-title {
    margin-bottom: 0;
    color: #8e939b;
    font-size: 17px;
    font-weight: 600;
    margin-top: 0px;
}

.order-clock img {
    opacity: 0.5;
}

.steps .step:first-child .step-icon-wrap::before {
    /* display: none */
}

.steps .step:last-child .step-icon-wrap::after {
    /* display: none !important */
}

.steps .step.completed .step-icon-wrap::before,
.steps .step.completed .step-icon-wrap::after {
    border: 0.2px #FF4200 dashed !important;
}

.steps .step.step-icon-wrap::before,
.steps .step .step-icon-wrap::after {
    border: 0.2px #ddd dashed !important;
}


.steps .step:last-child .step-icon-wrap::before,
.steps .step:last-child .step-icon-wrap::after {
    border: none;
}

.steps .step.completed .step-icon {
    border-color: #FFDBCE;
    background-color: #FFDBCE;
    color: #fff
}

.process .step-icon img {
    opacity: 0.5;
}

.pill-schedule-row {
    background: #F5F5FB;
    padding: 18px 25px;
    border-radius: 10px;
    display: flex;
    gap: 25px;
    -webkit-gap: 25px;
    align-items: center;
    margin: 20px 0;
    justify-content: space-between;
}

.pill-schedule-row h4 {
    margin-top: 0px;
    font-size: 16px;
    /* font-weight: 600; */
}

.pill-schedule-row h5 {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    color: #5e5e5ee0;
}

.schedule-possin {
    padding-left: 10px;
}

.reminder-left h5 {
    font-size: 17px;
    font-weight: 600;
}

.remider-col {
    display: flex;
    align-items: center;
    gap: 20px;
}

.reminder-right {
    background: #FF4200;
    width: 44px;
    height: 44px;
    border-radius: 10px;
    text-align: center;
    line-height: 40px;
}

.reminder-right a {
    display: inline-block;
    padding: 8px 10px;
}

.pill-schedule-left {
    display: flex;
    align-items: center;
    gap: 18px;
}

.pill-schedule-right ul {
    padding: 0px;
    margin: 0px;
}

.pill-schedule-right ul li {
    display: inline-block;
    padding: 0 10px;
}

.form-pill input {
    position: relative;
    font-family: 'Jost', sans-serif;
    line-height: 20px;
    font-size: 17px;
}

.form-pill span.form-icon {
    position: absolute;
    top: 15px;
    left: 12px;
    color: #1111118c;
}

.form-pill label {
    padding-left: 40px;
    padding-top: 12px;
    font-family: 'Jost', sans-serif;
    line-height: 26px;
    font-size: 17px;
    color: #11111191;
}

.Pill-select-box select.form-select {
    min-height: 52px;
    max-height: 52px;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 10px;
    font-family: 'Jost', sans-serif;
    font-size: 17px;
    /* text-align: right; */
    width: 377px;
    padding-left: 36px;
}

.Pill-select-box select.form-select:focus {
    outline: none;
    box-shadow: none;
}

.Pill-select-box {
    width: 180px;
    display: inline-block;
    margin-right: 15px;
    position: relative;
}

.Pill-select-col label {
    width: 100%;
    font-family: 'Jost', sans-serif;
    line-height: 26px;
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 15px;
}

.pill-amount {
    position: absolute;
    top: 12px;
    left: 12px;
    opacity: 0.6;
}

.food-col label {
    width: 100%;
    font-family: 'Jost', sans-serif;
    line-height: 26px;
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 15px;
}

.food-col {
    margin-top: 20px;
}

.food-col .form-check-input[type=checkbox] {
    width: 110px;
    max-width: 110px;
    min-height: 100px;
    border-radius: 15px;
    background-image: url(../public/images/food-dish.svg) !important;
    background-color: transparent;
    background-size: 64px;
}

.pill-check-box {
    position: relative;
    width: 110px;
    max-width: 110px;
    min-height: 100px;
    line-height: 100px;
    display: inline-block;
    margin-right: 12px;
}

.check-box-icon {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
}

.food-col .form-check-input:checked[type=checkbox] {
    background-image: url(../public/images/food-dish-hover.svg) !important;
    background-color: #003E82;
    background-size: 64px;
    opacity: 1;
}

.notification-box input.form-control {
    min-height: 52px;
    max-height: 52px;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 10px;
    font-family: 'Jost', sans-serif;
    font-size: 17px;
    padding-left: 50px;
}

.notification-box span.form-icon {
    position: absolute;
    top: 12px;
    left: 12px;
    color: #1111118c;
    opacity: 0.6;
}

.notification-box {
    position: relative;
    display: flex;
    gap: 20px;
}

.notification-box button {
    background: #ff420038;
    width: 60px;
    height: 52px;
    border-radius: 10px;
    border: none;
    color: #FF4200;
    font-size: 30px;
    line-height: 16px;
    padding: 6px 10px;
}

.page-title-breadcrumb {
    background: #003e821c;
    padding: 45px;
    border-radius: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.page-title-breadcrumb h2 {
    color: #003E82;
    font-size: 30px;
    margin: 0px;
    line-height: 30px;
    font-family: 'Jost', sans-serif;
}

.page-title-breadcrumb ol {
    margin: 0px;
    font-family: 'Jost', sans-serif;
    font-size: 17px;
}

.page-title-breadcrumb ol li,
.page-title-breadcrumb ol li a {
    color: #003E82;
    text-decoration: none;
}

.page-title-breadcrumb ol li.active {
    color: #003E82;
}

.page-title-breadcrumb ol li {
    padding: 0 7px;
}

.pagination-col ul.pagination li {
    padding: 7px;
}

.pagination-col ul.pagination li a {
    background: #F7F6F9;
    border: none;
    width: 70px;
    height: 70px;
    text-align: center;
    font-family: 'Jost', sans-serif;
    font-size: 20px;
    border-radius: 12px;
    line-height: 55px;
    font-weight: 500;
    color: #222;
}

.pagination-col ul.pagination li.active span.page-link {
    background: #003E82;
    border: none;
    width: 70px;
    height: 70px;
    text-align: center;
    font-family: 'Jost', sans-serif;
    font-size: 20px;
    border-radius: 12px;
    line-height: 55px;
    font-weight: 500;
    color: #fff;
}

.pagination-col ul.pagination li span.page-link {
    background: #F7F6F9;
    border: none;
    width: 70px;
    height: 70px;
    text-align: center;
    font-family: 'Jost', sans-serif;
    font-size: 20px;
    border-radius: 12px;
    line-height: 55px;
    font-weight: 500;
    color: #fff;
}

.pagination-col ul.pagination li a:hover {
    background: #003E82;
    color: #fff;
}

.search-widget form.search-form input {
    background: #F5F5FB;
    font-family: 'Jost', sans-serif;
    font-size: 18px;
    min-height: 75px;
    border-radius: 15px;
    border: none;
    padding-left: 50px;
}

.search-widget {
    position: relative;
}

.search-widget form.search-form .submit {
    position: absolute;
    top: 22px;
    left: 20px;
    font-size: 20px;
}

.blog-widget {
    background: #F5F5FB;
    margin-top: 30px;
    border-radius: 22px;
    padding: 30px;
}

.blog-widget h3.title {
    width: 100%;
    text-align: left;
    font-weight: 600;
    font-size: 30px;
    line-height: 50px;
    display: inline-block;
    position: relative;
}

.blog-widget h3.title .title-block {
    display: inline-block;
    position: relative;
    padding: 0;
    z-index: 1;
}

.blog-widget h3.title .title-block:after {
    display: block;
    position: absolute;
    background: #ff42005c;
    top: 32px;
    width: 100%;
    height: 5px;
    content: '';
    z-index: -1;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.widget_categories ul {
    padding: 0px;
    margin: 0px;
}

.widget_categories ul li {
    list-style-type: none;
    line-height: 45px;
    position: relative;
}

.widget_categories ul li a {
    font-family: 'Jost', sans-serif;
    font-size: 18px;
    color: #191919;
    text-decoration: none;
}

.widget_categories ul li a:hover {
    color: #FF4200;
}

.cat-link {
    position: absolute;
    right: 0;
}

.widget-popular-post .item {
    display: flex;
    gap: 12px;
    margin: 25px 0;
}

.widget-popular-post .item .info h4.title-text {
    font-size: 18px;
}

.widget-popular-post .item .info h4.title-text a {
    color: #222;
    font-weight: 600;
    text-decoration: none;
}

.widget-popular-post .item .info h4.title-text a:hover {
    color: #FF4200;
}

.post-time {
    font-family: 'Jost', sans-serif;
    font-size: 15px;
    color: #19191985;
}

.blog-widget-tag {
    padding: 0px;
    margin: 0px;
}

.blog-widget-tag li {
    list-style-type: none;
    display: inline-block;
    margin: 7px;
}

.blog-widget-tag li a {
    text-decoration: none;
    font-family: 'Jost', sans-serif;
    font-size: 17px;
    color: #19191985;
    font-weight: 600;
    background: #fff;
    padding: 8px 15px;
    border-radius: 7px;
    line-height: 40px;
}

.blog-widget-tag li a:hover {
    color: #fff;
    background: #FF4200;
}

.blockquote {
    font-size: 1.25rem;
    background: rgba(255, 66, 0, 0.1);
    padding: 30px;
    border-radius: 25px;
    box-shadow: 0px 3px 0px 1px #ff4200;
    margin: 30px 0;
}

.article-author {
    background: #003E82;
    border-radius: 30px;
    padding: 35px;
    display: flex;
    gap: 25px;
}

.article-author-right h3,
.article-author-right p {
    color: #fff;
}

.author-social-link ul.social-icon {
    padding: 0px;
    margin: 0px;
}

.author-social-link ul.social-icon li {
    display: inline-block;
    padding: 0 4px;
}

.author-social-link ul.social-icon li a {
    color: #fff;
    width: 32px;
    height: 32px;
    border: 1px #fff solid;
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
}

.author-social-link ul.social-icon li a:hover {
    color: #fff;
    border: 1px #FF4200 solid;
    background: #FF4200;
}

.comments-wrap {
    margin: 70px 0;
}

.comments-wrap .title {
    text-align: left;
    font-size: 30px;
    font-weight: 600;
    display: inline-block;
    position: relative;
    z-index: 1;
}

.comments-wrap .title:after {
    display: block;
    position: absolute;
    background: #ff42005c;
    bottom: -2px;
    width: 100%;
    height: 6px;
    content: '';
    z-index: -1;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.comments-wrap ul li {
    display: flex;
    gap: 40px;
}

.comments-col-left {
    width: 100px;
    min-width: 100px;
}

.comments-col-left img {
    width: 100%;
    min-width: 100%;
}

.comments-col-right h3 {
    font-size: 18px;
    font-weight: 600;
}

.comments-wrap ul {
    padding: 0px;
    margin: 0px;
    margin-top: 40px;
}

.comments-wrap ul li .comments-col-right a {
    font-family: 'Jost', sans-serif;
    font-size: 17px;
    color: #FF4200;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 500;
}

.comments-wrap ul li {
    border-bottom: 1px #ddd solid;
    padding: 25px 0;
    margin-left: 150px;
}

.comments-wrap ul li:first-child {
    margin-left: 0px;
}

.comments-wrap ul li:last-child {
    border-bottom: none;
}

.comments-form .contact-form h3 {
    text-align: left;
    font-size: 30px;
    font-weight: 600;
    display: inline-block;
    position: relative;
    z-index: 1;
}

.comments-form .contact-form h3:after {
    display: block;
    position: absolute;
    background: #ff42005c;
    bottom: 4px;
    width: 100%;
    height: 6px;
    content: '';
    z-index: -1;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.comments-form #contactForm .form-floating {
    position: relative;
}

.comments-form #contactForm .form-floating .form-icon {
    position: absolute;
    top: 15px;
    left: 12px;
    color: #191919;
    opacity: 0.5;
}

.comments-form #contactForm .form-floating input {
    background: #F5F5FB;
    border: 1px #F5F5FB solid;
    padding-left: 40px;
    font-family: 'Jost', sans-serif;
    font-size: 17px;
}

.comments-form #contactForm .form-floating>label {
    padding-left: 40px;
    font-family: 'Jost', sans-serif;
    font-size: 18px;
    padding-top: 12px;
    color: #a5a5a5;
}

.textarea-comments textarea {
    background: #F5F5FB;
    border: 1px #F5F5FB solid;
    padding: 20px;
    font-family: 'Jost', sans-serif;
    font-size: 20px;
}

.comments-btn {
    padding: 12px 35px;
    font-family: 'Jost', sans-serif;
    font-size: 18px;
}

.wx_myprofileTextRight .list-group {
    position: absolute;
    z-index: 1;
    width: 100%;
}

/* responsive */


/* @media (min-width: 767px) {
    .justify-center-lg{
        justify-content: center;
    }

}
@media (min-width: 1200px) and (max-width: 1400px) {
    .heroimg {
        height: 462px;
        margin-bottom: -41px;

    }
    .container {
        max-width: 1200px !important;
    }
    .polygon {
        clip-path: polygon(156% -3%, -19% 100%, 100% 100%);
        margin-top: -214px;
    }
}
@media (min-width: 320px) and (max-width: 767px) {
    .wx_ReviewRatingBg{
        width: 100% !important;
    }
    .wx_ReviewRatingBgInner {
        margin-left: 0;
        margin-top: 3rem;
    }
    .wx_ReviewRatingBg .grid-item {
        width: 100%;
        padding: 15px;
        margin-bottom: 0;
    }
    .wx_RatingRightSec img:nth-child(2) {
        position: unset;
        width: 100%;
    }
    .wx_ReviewRatingBg{
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 20px;
    }
    .navbar-toggler{
        color:var( --default-orange) !important;
        border:none !important;
    }
    .navbar-toggler:focus{
        box-shadow: none;
    }
    #headercollpase .nav-item {
    padding: 0px 5px;
    width: 100%;
    border-bottom: 1px #ddd dashed;
}
#headercollpase .nav-item:last-child {
    width: 130px;
    border-bottom: none;
    margin-bottom: 0px;
}
#headercollpase .btn-hero {
     margin-bottom: 0px;
}
.nav-item {
    padding-left: 0px;
}
    .nav-item.active .nav-link::before{
        left: 0px;
    }
    .polygonfooter{
        clip-path: polygon(115% 48%, -1% 100%, 104% 100%);
        margin-bottom: -1px;
    }
    .left-banner-content h2 {
        font-size: 36px;
        line-height: 40px;
    }
    .left-banner-content h3 {
        font-size: 36px;
    }
    .btn-hero{
        padding: 8px 80px !important;
        margin-bottom: 25px;
    }
    .heroimg{
        margin-bottom: -100px;
    }
    .footercopyright{
        font-size: 12px;
    }
    .googleplay, .applestore{
        font-size: 15px;
        padding: 8px 19px;
    }
    .googleplay i, .applestore i{
        font-size: 15px;
    }
    .footer-container{
        padding: 7px;
        background-color: var(--default-blue) !important;
        margin: 0px;
        margin-top: 0px;
    }
    .footer-logo-small {
    padding: 10px;
    margin-top: 50px;
    text-align: center;
    margin-bottom: 50px;
}
    .policty li a{
        font-size: 12px;
    }
    .customfooter {
        padding-top: 25PX !important;
        border-radius: 0px !important;
    }
    .subscribeInputContainer {
        padding: 6px;
    }
    .spaceContainer{
        padding: 45px 15px;
    }
    .section-title h2 {
        font-size: 36px;
        line-height: 40px;
    }
    .herosection{
        padding: 0px 15px;
        border-radius: 0px;
    }
    .p-90{
        padding: 100px 14px;
    }
    .polygon ,.polygonfooter{
        display: none;
    }
   .heroimg{
    width: 100%;
    height: 100%;
   } 
   .mw-360px, .gmail-btn, .title,.btn-hero{
    width: 100% !important;
   }
   .cta{
    padding: 20px 28px !important;
    border-radius: 0px;
   }
   .cta-phone{
    
    padding-right: 0px !important;
    padding-left: 0px !important;
   }
   .cta h4{
    font-size: 22px;
   }
   h1{
    font-size: 37px;
    line-height: 44px;
   }
   p{
    font-size: 18px;
    line-height: 26px;
   }
   .section-title h2:after {
    width: 50%;
    margin: 0 auto;
   }
   .navbar-nav {
     align-items: baseline;
   }
.opt-box ul li {
    font-size: 15px;
    width: 34px;
    height: 34px;
    line-height: 40px;
}
.myform {
    padding: 2rem;
}
.mylogin-form h3 {
    font-size: 30px;
}
.mylogin-form h2 {
    font-size: 36px;
    line-height: 20px;
}
.mylogin-form h3 {
    margin-top: 25px;
    margin-bottom: 20px;
}
.login-btn {
    margin-top: 0px;
}
.mylogin-form p{
    margin-bottom: 0px !important;
}
.recive-code {
    margin-top: 50px;
    margin-bottom: 20px;
}
.trading ul li {
    margin: 8px 2px;
}
.profile-right-col {
    padding-left: 0px;
}
.left-login-bottom {
    margin-top: 50px;
}
.page-title-breadcrumb {
    display: block;
}
.article-author{
    display: block;
}
.article-author-left {
    text-align: center;
    margin-bottom: 20px;
}
.article-author-right{
    text-align: center;
}
.comments-wrap ul li {
    border-bottom: 1px #ddd solid;
    padding: 25px 0;
    margin-left: 25px;
}
.page-title-breadcrumb h2 {
    margin-bottom: 20px;
}
.pagination-col ul.pagination li.active span.page-link{
    width: 40px;
    height: 40px;
    font-size: 15px;
    line-height: 30px;
}
.pagination-col ul.pagination li a{
    width: 40px;
    height: 40px;
    font-size: 15px;
    line-height: 30px;
}
.pagination-col ul.pagination li span.page-link {
    width: 40px;
    height: 40px;
    font-size: 15px;
    line-height: 30px;
}
.pagination-col ul.pagination li span img {
    width: 20px;
}
.pagination-col ul.pagination li a img {
    width: 20px;
}


}
@media (min-width: 320px) and (max-width: 900px) {
    .policty li{
        border: none !important;
    }
    .container{
        min-width: 100% !important;
    }
    .policty li i {
        font-size: 7px;
    }
    
} */

.user-dropdown-group {
    margin-top: 35px;
}

.user-dropdown-toggle img {
    width: 75px;
    border-radius: 5px;
}

.user-dropdown-group .dropdown-toggle {
    display: flex;
    align-items: center;
    max-width: 100%;
    min-width: 100%;
    justify-content: space-between;
    border: none;
}

.user-dropdown-group .dropdown-toggle:hover,
.user-dropdown-group .dropdown-toggle:focus {
    border: none;
    outline: none;
    box-shadow: none;
}

.user-dropdown-group .dropdown-toggle .user-dropdown-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.user-dropdown-toggle .media-body {
    text-align: left;
}

.user-dropdown-toggle .media-body h5 {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
}

.dropdown-toggle::after {
    display: none;
}

.user-dropdown-group ul.dropdown-menu {
    padding: 20px;
    max-width: 300px;
    min-width: 300px;
    border-radius: 20px;
}

.user-dropdown-group ul.dropdown-menu li {
    line-height: 40px;
}

.selectweeks {
    display: flex;
    margin-top: -8px;
}

.selectCheckBox {
    font-size: 12px;

}

.map-box {
    position: relative;
}

.map-box img {
    width: 100%;
}

.map-box .map-pointer {
    width: 70px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: 0 auto;
    text-align: center;
    transform: translate(0px, 50%);
}

.team-card p {
    font-size: 16px;
}

.contact-col img {
    max-width: 20px;
    margin-bottom: 10px;
}

.contact-col h5 {
    color: #003E82;
    font-weight: 500;
}

.contact-col ul {
    padding-left: 0px;
}

.contact-col ul li {
    display: inline-block;
}

.contact-col ul li a {
    color: #4A4F61;
    border: 1px #4A4F61 solid;
    width: 30px;
    height: 30px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    line-height: 28px;
}

.contact-col ul li a:hover {
    color: #fff;
    border: 1px #4A4F61 solid;
    background: #4A4F61;
}

.contact-details {
    background: #003E82;
    padding: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 90px;
    margin-top: 15px;
}

.contact-details-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.contact-box-details h6 {
    color: #fff;
    font-weight: 400;
    font-size: 17px;
}

.contact-box-details h3 {
    color: #fff;
    font-weight: 700;
    font-size: 28px;
}

.contact-from-box {
    background: #F5F5FB;
    padding: 35px;
    border-radius: 22px;
}

.contact-from-box h3 {
    font-size: 36px;
    font-weight: 700;
}

.about-content h5 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 30px;
    line-height: 28px;
    border-left: 3px #222 solid;
    padding-left: 35px;
}

.about-btn {
    background: #FF4200;
    padding: 15px 30px;
    border-radius: 7px;
    color: #fff;
    text-decoration: none;
    font-family: 'Jost', sans-serif;
    font-size: 17px;
    margin-top: 15px;
    display: inline-block;
}

.about-btn:hover {
    background: #FF4200;
    color: #fff;
}

.appointment-btn {
    padding: 15px 30px;
    border-radius: 7px;
    color: #191919;
    text-decoration: none;
    font-family: 'Jost', sans-serif;
    font-size: 17px;
    font-weight: 600;
    margin-top: 15px;
    display: inline-block;
}

.appointment-btn:hover {
    color: #191919;
}

.appintment-row {
    background: #F5F5FB url(../public//images/apintment-bg.svg) !important;
    padding: 120px 0 0 0;
}

.appinint-box {
    padding: 40px;
    background: #fff;
    border-radius: 25px;
    margin: 15px 0;
    min-height: 210px;
}

.appinint-box h5 {
    font-size: 18px;
    font-weight: 600;
    margin-top: 15px;
}

.healthcare-section {
    background: #003E82;
    padding: 200px 0 80px 0;
    margin-top: -115px;
}

.healthcare-left h4,
.healthcare-left p {
    color: #fff;
}

.healthcare-left p {
    font-weight: 300;
    font-size: 20px;
}

.healthcare-right {
    background: #034996;
    padding: 45px;
}

.team-card p {
    font-size: 16px;
}

.team-cat {
    color: #FF4200;
    font-family: 'Jost', sans-serif;
    margin-bottom: 6px;
    display: inline-block;
}

.wx_secSpacing {
    padding: 4rem 0;
}

.wx_reviewBg {
    box-shadow: 20px 20px 80px rgba(112, 144, 176, 0.08);
    border-radius: 25px;
    border: none;
}

.wx_reviewBg ul {
    grid-gap: 10px;
    padding-left: 0;
}

.wx_reviewBg ul li {
    list-style: none;
}

.wx_reviewRightSecBg .wx_reviewInnerLine {
    position: relative;
}

.wx_RatingRightSec {
    position: relative;
}

.wx_ReviewRatingBg .card {
    border: none;
    background: #F5F5FB;
    border-radius: 25px;
    height: fit-content;
}

.wx_ReviewRatingBg h5 {
    font-size: 16px;
}

.wx_ReviewRatingBgInner .progress-bar {
    background-color: #003E82;
}

.wx_RatingRightSec img:nth-child(2) {
    position: absolute;
    left: -20rem;
    bottom: -34px;
    width: 62%;
}

.wx_ReviewRatingBgInner .progress:last-child {
    margin-bottom: 0;
}

.wx_ReviewRatingBgInner .progress {
    height: 10px;
    background: #F5F5FB;
    margin-bottom: 2rem;
    margin-left: 30px;
}

.grid-header {
    text-align: center;
}

.grid {
    margin: 1rem auto;
}

.grid-item {
    width: 400px;
    height: auto;
    margin-bottom: 20px;
}

.wx_ReviewRatingBgInner {
    position: relative;
    margin-left: 3rem;
}

.wx_ReviewRatingBgInner span {
    position: absolute;
    left: 0;
    line-height: 5px;
    letter-spacing: 0.02em;
    color: #191919;
    font-weight: 700;
    font-size: 17px;
}

.wx_ReviewRatingBg .card .d-flex.align-items-center p {
    text-align: right;
    letter-spacing: 0.02em;
    font-weight: 400;
    font-size: 13px;
    color: #4A4F61;
    line-height: normal;
    position: absolute;
    bottom: 0;
    right: 0;
}

.wx_ReviewRatingBg .flex-shrink-0 {
    background: #003E82;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.wx_ReviewRatingBg h6 {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #191919;
}

.wx_ReviewRatingBg p {
    font-weight: 400;
    font-size: 17px;
    line-height: 35px;
    letter-spacing: 0.02em;
    color: #191919;
}

.wx_secSpacing {
    padding: 4rem 0;
}

.wx_ReviewRatingBg ul li {
    list-style: none;
}

.wx_ReviewRatingBg ul {
    padding: 0;
}

.chart {
    /* width: 500px; */
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
}

.chart .rate-box {
    display: flex;
    gap: 15px;
    height: 30px;
    padding: 20px 0;
    padding: 10px;
}

.chart .rate-box>* {
    height: 100%;
    display: flex;
    align-items: left;
    font-weight: 500;
    color: #444;
}

.rate-box .value {
    display: flex;
    align-items: center;
}

.rate-box .value:hover {
    color: #66bb6a;
}

.chart .value {
    font-size: 30px;
    cursor: pointer;
}

.rate-box .progress-bar {
    border-width: 1px;
    position: relative;
    background-color: #cfd8dc91;

    height: 10px;
    border-radius: 100px;
    width: 350px;
}

.rate-box .progress-bar .progress {
    background-color: #66bb6a;
    height: 100%;
    border-radius: 100px;
    transition: 300ms ease-in-out;
}

.wx_autoSearchField .list-group {
    position: absolute;
    width: 100%;
    z-index: 1;
}

.global {
    height: 100%;
    width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.one .fas {
    color: #cfd8dc;
}

.two {
    background: linear-gradient(to right, #66bb6a 0%, transparent 0%);
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    transition: 0.5s ease-in-out all;
}

.global>span {
    font-size: 90px;
    font-weight: 500;
}

.rating-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 10%;
}

.rating-icons span {
    position: absolute;
    display: flex;
    font-size: 30px;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 5px;
}

.total-reviews {
    font-size: 25px !important;
}

.dashboardbtn {
    margin-top: 130px !important;
}


.dashboard {
    margin-top: 20px;
}

.dashboard .heading {
    margin-bottom: 20px
}

.dashboard .heading h1 {
    font-size: 30px;
    margin: 0;
    padding: 0;
}

.dashboard .dashboard-col {
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 20px;
    background: #fff;
    border-radius: 20px
}

.dashboard .dashboard-col h4 {
    color: var(--default-blue);
    margin: 0;
    padding: 0
}

.dashboard .dashboard-col p {
    padding: 0;
    margin: 0 0 60px 0;
}

.dashboard .dashboard-col a {
    color: var(--default-orange);
    text-decoration: none;
}

.dashboard .dashboard-col a:hover {
    color: var(--default-blue)
}

/*--07-03-2023--*/

.wx_pillRemainderBg {
    border: none;
    border-radius: 10px;
    padding: 1.2rem;
}

.wx_pillRemainderBg h6 {
    font-size: 17px;
    font-weight: 600;
}

.wx_pillRemainderBg .card-header {
    background: transparent;
    border: none;
}

.wx_pillRemainderBg .input-group span:first-child {
    background: transparent;
    border: none;
    position: absolute;
    z-index: 12;
    height: 55px;
}

.wx_pillRemainderBg .input-group span:last-child {
    background: transparent;
    border: none;
    position: absolute;
    z-index: 12;
    right: 0;
    height: 55px;
}

.wx_pillRemainderBg .input-group input {
    border: 1px solid rgba(17, 17, 17, 0.15);
    border-radius: 10px !important;
}

.wx_pillRemainderBg .input-group input,
.wx_pillRemainderBg .input-group label {
    padding: 10px 3rem;
    height: 55px;
}

.wx_pillRemainderBg ul {
    display: table;
    width: 100%;
    text-align: center;
}

.wx_pillRemainderBg ul li {
    display: table-cell;
    padding: 0;
    border: none;
}

.wx_pillRemainderBg ul li button {
    width: 100%;
    border: none;
    font-weight: 600;
    font-size: 17px;
    color:
        rgba(17, 17, 17, 0.3);
}

.wx_LogoutBtn {
    margin-top: 2rem;
}

.wx_pillRemainderBg ul li button:hover,
.wx_pillRemainderBg ul li button:focus {
    border: none;
}

.wx_pillRemainderBg ul li button.nav-link.active {
    color: var(--default-blue);
    font-weight: 600;
    font-size: 17px;
    border-bottom: 2px solid var(--default-blue) !important;
    border: none;
    outline: none;
}

.form-floating.wx_CunsumeSelect select {
    position: absolute;
    top: 0;
    right: 10px;
    width: 70px;
    padding: 0 !important;
    border: none;
    outline: none;
    cursor: pointer;
    background: transparent;
    height: 8px;
    z-index: 123;
    box-shadow: none;
}

.wx_MonthlyDate {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    text-align: center;
}

.wx_MonthlyDate p {
    color: #003E82;
    font-weight: 400;
    font-size: 14px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    padding: 8px 10px;
    line-height: normal;
    display: flex;
    justify-content: center;
}

.wx_MonthlyDate img {
    margin-left: 10px;
}

.my-datepicker {
    border: none;
    background-color: #f5f5fB;
    outline: none;
    cursor: pointer;

}

.my-datepicker::placeholder {
    color: #003E82;
}

.my-datepicker:focus {
    outline: none;
}

.wx_MonthlyDateBtn p {
    background: #F5F5FB;
    border-radius: 37.5px;
    padding: 10px 50px;
    display: inline-block;
    justify-content: center;
    vertical-align: middle;
    display: flex;
    width: 280px;
    height: 55px;
    align-items: center;
    margin: 1.5rem auto;
    color: #003E82;
}

.wx_dayOfWeekSec {
    margin-top: 2rem;
}

.wx_dayOfWeekSec h6 {
    font-weight: 600;
    font-size: 17px;
    color: #111111;
}

.wx_dayOfWeekBtn {
    padding-top: 1rem;

}

.AddWeekly span {
    color: rgba(17, 17, 17, 0.5);
    font-weight: 400;
    font-size: 14px;
    background: #F5F5F5;
    border-radius: 5px;
    padding: 8px 10px;
    margin: 2px;
    display: inline-block;
    margin-left: 5px;
}

.wx_dayOfWeekBtn span {
    color: rgba(17, 17, 17, 0.5);
    font-weight: 400;
    font-size: 14px;
    background: #F5F5F5;
    border-radius: 5px;
    padding: 8px 16px;
    margin: 2px;
}

.wx_SetReminder {
    background: #FF4200;
    border-radius: 10px;
    color: #fff;
    padding: 8px 30px;
    font-weight: 600;
    font-size: 17px;
    border: 1px solid #FFF;
    transition: 0.3s;
}

.wx_SetReminder:hover,
.wx_SetReminder:focus {
    outline: none;
    background: transparent;
    color: #FF4200 !important;
    border: 1px solid #FF4200 !important;
}

.list-icon-row .list-icon-col .list-icon.active {
    background: rgba(255, 66, 0, 0.05);
}

.checkedBgMain {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 10px;
}

.checkedBgMain .form-check {
    padding-left: 0;
}

.checkedBg input {
    display: none;
}

.checkedBg label {
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 5px;
    color: #111111;
    border: 1px solid #b8b9bb;
}

.checkedBg input+.checkedBg label {
    /* Unchecked style  */
    background-color: #ccc;
    color: #fff;
    padding: 5px 10px;
    font-family: sans-serif;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius: 4px;
    display: inline-block;
    margin: 0 10px 10px 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: all 0.6s ease;

}

.checkedBg input:checked+label {
    background-color: var(--main_clr);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    cursor: pointer;
    padding: 5px 15px;
    color: #FF4200;
    background: rgba(255, 66, 0, 0.05);
    border-radius: 5px;
    border: 1px solid rgba(255, 66, 0, 0.05);
    -webkit-transition: all .25s linear;
    -moz-transition: all .25s linear;
    -ms-transition: all .25s linear;
    -o-transition: all .25s linear;
    transition: all .25s linear;
}

/* My Profile Page */
.wx_myprofileTextLeft .nav {
    width: 100%;
}

.wx_myprofileTextLeft button i {
    float: right;
    line-height: 45px;
}

.wx_myprofileTextLeft button:hover {
    color: #FF4200;
}

.wx_myprofileTextLeft button:hover svg path {
    fill: #FF4200;
}

.wx_myprofileTextLeft button:hover svg {
    background-color: rgba(255, 66, 0, 0.1) !important;
}

.wx_myprofileTextLeft button {
    width: 100%;
    padding: 10px 0 10px 0;
    text-align: left;
    font-weight: 600;
    border-top: 1px solid rgba(17, 17, 17, 0.1) !important;
    border-radius: 0 !important;
    transition: .3s;
}

.wx_myprofileTextLeft button.nav-link.active {
    background-color: transparent;
    color: #000;
}

.wx_myprofileTextLeft button.nav-link.active svg {
    background-color: rgba(255, 66, 0, 0.1);
    border-radius: 10px;
    width: 45px;
    height: 45px;
    padding: 12px;
}

.wx_myprofileTextLeft button.nav-link.active svg path {
    fill: #FF4200;
}

.wx_myprofileTextLeft button.nav-link svg {
    background-color: #F5F5FB;
    border-radius: 10px;
    width: 45px;
    height: 45px;
    padding: 12px;
}

.wx_BackToHome {
    display: flex;
    align-items: center;
    grid-gap: 13px;
    padding: 1rem 0;
    /* border-bottom:1px solid rgba(17, 17, 17, 0.1); */
}

.wx_BackToHome a {
    font-weight: 500;
    text-decoration: none;
    color: #000000;
    font-size: 17px;
}

.wx_BackToHome svg {
    background-color: #111111;
    border-radius: 10px;
    width: 45px;
    height: 45px;
    padding: 12px;
}

/* .wx_myprofileBg{
    height: 100vh;
} */
.wx_LogOutBg {
    margin-top: 20px;
}

.wx_myprofileTextRight {
    border-radius: 10px;
    border: 0;
    margin-top: 7rem;
}

.wx_myProfileCard h4 {
    margin: 0;
}

.wx_myProfileCard p {
    color: #4A4F61;
}

.wx_myProfileCard {
    margin-top: -70px;
    text-align: center;
}

.wx_myprofileTextRight h4 {
    font-weight: 700;
    font-size: 25px;
    color: #191919;
    opacity: 0.9;
}

.wx_myprofileTextRight label {
    color: #191919;
    opacity: 0.5;
}

.wx_myprofileTextRight span.form-icon {
    position: absolute;
    top: 14px;
    left: 15px;
    color: #a5a5a5;
}

.wx_myprofileTextRight .form-floating>label {
    top: -4px;
    font-size: 17px;
    font-family: 'Jost', sans-serif;
    font-weight: 400;
    color: #7c7c7c;
    padding-left: 40px;
}

.wx_myprofileTextRight .input-group.date input {
    border-left: 0;
}

.wx_myprofileTextRight span.input-group-addon {
    border-right: 0;
    border-radius: 10px;
    background: #003E82;
}

.wx_myprofileTextRight input {
    border: 1px solid rgba(25, 25, 25, 0.15);
    border-radius: 10px;
    height: 55px;
}

.wx_myprofileTextLeftBg .dropdown-toggle::after,
.wx_myprofileTextLeftBg .dropdown-menu {
    display: none;
}

.wx_myprofileTextLeftBg button.accordion-button {
    display: contents;
}

.wx_ShowHideTab {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F5F5FB;
    width: 39px;
    height: 39px;
    border-radius: 50%;
}

.wx_ShowHideTab button.accordion-button img {
    padding: 10px;
}

.wx_ShowHideTab button.accordion-button::after {
    margin: 0;
    display: none;
}

.wx_myprofileTextRight .list-group {
    position: absolute;
    z-index: 1;
    width: 100%;
}

.wx_myProfileCard img {
    border: 1px solid #FFFFFF;
    box-shadow: 10px 10px 55px rgba(112, 144, 176, 0.13);
    border-radius: 15px;
    width: 130px;
    height: 130px;
    object-fit: cover;
}

.wx_myprofileTextLeft button span {
    float: right;
    font-weight: 400;
    font-size: 14px;
    line-height: 45px;
    color: #111111;
    opacity: 0.6;
}

.wx_myprofileBgMain {
    position: sticky;
    top: 0;
    height: 100vh;
    display: table;
}

.wx_MyProfileBg input.form-check-input,
.wx_MyProfileBg input.form-check-input:focus {
    height: 1em;
    box-shadow: none;
}

.wx_MyProfileBg input.form-check-input:checked,
.wx_MyProfileBg input.form-check-input:checked:focus {
    height: 1em;
    border: none;
    background-color: #FF4200 !important;
    box-shadow: none;
}

.wx_settingBoXBg {
    margin-bottom: 2rem;
}

.wx_settingBoXBg h6 {
    color: #191919;
    font-size: 17px;
    font-weight: 600;
}

.wx_settingBoXBg p {
    color: #191919;
    opacity: 0.5;
    font-weight: 400;
    font-size: 17px;
}

.wx_PrivacyPolicyBg h6 {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #191919;
    opacity: 0.9;
}

.wx_PrivacyPolicyBg p {
    font-weight: 400;
    font-size: 20px;
    line-height: 40px;
    color: #191919;
    opacity: 0.9;
    margin-left: 1.5rem;
}

.wx_myProfileSpc {
    padding: 0 0 2rem
}

.wx_MyContactBg a {
    color: #FF4200;
}

.wx_MyContactBg input.form-check-input {
    height: 1em;
    padding-left: 0 !important;
    min-height: 0em;
    box-shadow: none;
}

.wx_MyContactBg textarea {
    resize: none;
}

.wx_MyContactBg input.form-check-input:checked {
    background-color: #FF4200;
    box-shadow: none;
    border: none;
}

.wx_MyContactBg .form-check-label {
    margin-left: 10px;
}

/* Language Select */
div.dropdown {
    position: relative;
    width: 160px;
    margin-left: 20px;
}

div.dropdown:after {
    content: "";
    position: absolute;
    margin: 0;
    width: 16px;
    height: 17px;
    background-image: url(http://botkits.ru/img/icons/down.svg);
    top: 15px;
    right: 10px;
    -webkit-transition: .3s;
    transition: .3s;
}

div.dropdown.open:after {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}


div.dropdown>div.caption {
    background: #F5F5FB;
    border-radius: 10px;
    cursor: pointer;
    padding: 12.5px 15px 12.5px 55px;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.3px;
    display: flex;
    align-items: center;
    height: 55px;
}

div.dropdown>div.list {
    position: absolute;
    background-color: #fff;
    width: 100%;
    border-radius: 12px;
    -webkit-box-shadow: 0px 12px 24px rgba(21, 18, 51, 0.13);
    box-shadow: 0px 12px 24px rgba(21, 18, 51, 0.13);
    opacity: 0;
    overflow: hidden;
    -webkit-transition: all 0.15s cubic-bezier(0.25, 0, 0.25, 1.75), opacity 0.1s linear;
    transition: all 0.15s cubic-bezier(0.25, 0, 0.25, 1.75), opacity 0.1s linear;
    -webkit-transform: scale(0.85);
    transform: scale(0.85);
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
    top: 52px;
    z-index: -1;
    visibility: hidden;
    padding: 10px 0;
}

div.dropdown.open>div.list {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    z-index: 1;
    visibility: visible;
}

div.dropdown>div.list>div.item {
    padding: 10.5px 15px 10.5px 55px;
    cursor: pointer;
    -webkit-transition: .3s;
    transition: .3s;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.3px;
    display: flex;
    align-items: center;
}

div.dropdown>div.list>div.item.selected {
    background: rgba(36, 60, 187, 0.2);
    pointer-events: none;
}

div.dropdown>div.list>div.item:hover {
    background: #F8F9FB;
}

div.dropdown>div.caption img,
div.dropdown>div.list>div.item img,
div.dropdown>div.caption svg,
div.dropdown>div.list>div.item svg {
    position: absolute;
    margin-top: 2.5px;
    left: 15px;
}

div.dropdown>div.list>div.item span,
div.dropdown>div.caption span {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.3px;
    color: #243CBB;
    position: absolute;
    right: 36px;
}

div.dropdown>div.list>div.item span {
    right: 20px;
}

.wx_countrySearchText .input-group-text {
    background: transparent;
    border: none;
    position: absolute;
    z-index: 12;
    height: 55px;
}

.wx_countrySearchText input.form-control {
    border: 1px solid rgba(25, 25, 25, 0.15);
    border-radius: 10px !important;
    padding-left: 40px;
}

.wx_thankYouModal.modal.show .modal-dialog {
    margin-top: 0;
}

.wx_thankYouModal.modal.show .modal-content {
    border: none;
    border-radius: 30px;
}

.wx_thankYouModal.modal.show .modal-header {
    background: url('../public/images/myprofile/thankyou.png') #34A853;
    background-size: cover;
    padding: 3rem;
    border-radius: 30px 30px 0 0;
}

.wx_thankYouModal.modal.show .modal-header img {
    margin: 0 auto;
}

.wx_thankYouModal.modal.show .btn-close {
    position: absolute;
    right: 20px;
    top: 20px;
    opacity: 1;
    cursor: pointer;
}

.wx_thankYouModal.modal.show h4 {
    font-weight: 700;
    font-size: 35px;
    color: #34A853;
}

.wx_thankYouModal.modal.show .modal-body {
    padding: 2rem 1rem;
}

.wx_thankYouModal.modal.show p {
    font-weight: 400;
    font-size: 18px;
    color: #121212;
    opacity: 0.5;
    margin-bottom: 0;
}

.wx_myProfileSpc button.submitButton {
    padding: 8px 40px;
}

.myprofile_upload {

    position: relative;
}

.myprofile_upload input {
    width: 100%;
    background: transparent;
    opacity: 0;
    position: relative;
    z-index: 99;
}

.myprofile_upload button {
    position: absolute;
    top: 8px;
    left: 0px;
    width: 100%;
    background: #003E82;
    border-radius: 37.5px;
    color: #ffffff;
    border: none;
}

.wx_selectGender input {
    height: 1em;
}

.sendOtp,
.sendOtp:hover,
.sendOtp:focus,
.sendOtp.active {
    background: #F5F5FB;
    border-radius: 10px;
    color: #000000 !important;
    border: 1px solid #F5F5FB;
}

.wx_OTPModal .modal-content {
    border-radius: 30px;
    padding: 30px;
}

.wx_OTPModal .modal-dialog {
    margin-top: 0 !important;
}

.wx_OTPModal .modal-footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
}

.wx_OTPModal .modal-footer button:first-child {
    background: #FFF;
    border-radius: 10px;
    color: #000;
    border: none;
    height: 60px;
    font-weight: 600;
    font-size: 23px;
}

.wx_OTPModal .modal-footer button:nth-child(2) {
    background: #FF4200;
    border-radius: 10px;
    height: 60px;
    font-weight: 600;
    font-size: 23px;
}

.wx_OTPModal .modal-body input {
    background: #F5F5F5;
    border-radius: 10px;
    height: 60px;
    border: none;
}

.wx_OTPModal h1 {
    font-weight: 700;
    font-size: 35px !important;
}

.wx_FormCheck label {
    cursor: pointer;
}

.wx_FormCheck input.form-check-input {
    box-shadow: none;
    height: 1em;
}

.wx_FormCheck input.form-check-input:checked {
    background-color: #FF4200;
    border-color: #FF4200;
}

.datepicker-dropdown {
    z-index: 12 !important;
}

.cal {
    z-index: 500 !important;
    position: relative;
    /* pointer-events: none !important; */
    /* background-color: red; */
}

.select-box {
    position: relative;
    display: block;
    width: 100%;
    margin: 0 auto;
    font-size: 18px;
    color: #60666d;
    z-index: 1;
}

.select-box__current {
    position: relative;
    cursor: pointer;
    outline: none;
    min-height: 52px;
    max-height: 52px;
    border-radius: 10px;
    border: 1px solid #ced4da;
    overflow: hidden;
}

.select-box__current:focus+.select-box__list {
    opacity: 1;
    -webkit-animation-name: none;
    animation-name: none;
}

.select-box__current:focus+.select-box__list .select-box__option {
    cursor: pointer;
}

.select-box__current:focus .select-box__icon {
    transform: translateY(-50%) rotate(180deg);
}

.select-box__icon {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    width: 20px;
    opacity: 0.3;
    transition: 0.2s ease;
}

.select-box__value {
    display: flex;
}

.select-box__input {
    display: none;
}

.select-box__input:checked+.select-box__input-text {
    display: block;
}

.select-box__input-text {
    display: none;
    width: 100%;
    margin: 0;
    font-size: 18px;
    padding: 7px 40px;
}

.select-box__list {
    position: absolute;
    width: 100%;
    padding: 0;
    list-style: none;
    opacity: 0;
    -webkit-animation-name: HideList;
    animation-name: HideList;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: step-start;
    animation-timing-function: step-start;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(112, 144, 176, 0.2);
    border-radius: 20px;
    min-height: auto;
    max-height: 300px;
    overflow: auto;

}

.select-box__option {
    display: flex;
    grid-gap: 10px;
    padding: 15px;
}

.select-box__option li svg {
    height: 100%;
    width: 50px;
    margin-right: 10px;
}

.select-box__option:hover,
.select-box__option:focus {
    color: #546c84;
    background-color: #fbfbfb;
}

.wx_AutoSearch .form-icon {
    z-index: 1;
    position: absolute;
    top: 10px;
    left: 15px;
}

.wx_MyAddressBg a i {
    font-size: 38px;
    line-height: 20px;
    color: #fff;
    outline: none;
    border: none;
}

.wx_MyAddressRightList span.wx_TitleBox1 {
    background: #FFFFFF;
    border-radius: 5px;
    font-weight: 400;
    color: rgba(17, 17, 17, 60%);
    padding: 2px 1px;
    margin-right: 10px !important;
    font-size: 14px;
}

.wx_MyAddressRightList span.wx_TitleBox2 {
    background: #767E87;
    border-radius: 5px;
    /* font-weight: 400; */
    color: #ffffff;
    text-transform: uppercase;
    padding: 2px 8px;
    font-size: 13px;
}

.wx_AddAddressModal.modal.show .modal-dialog {
    margin-top: 40px;
}

.wx_AddAddressModal .modal-body .form-floating label svg {
    width: 24px;
    height: 24px;
}

.wx_AddAddressModal .modal-body .form-floating input,
.wx_AddAddressModal .modal-body .form-floating label {
    padding-left: 40px;
    top: -4px;
    font-size: 17px;
}

.wx_AddAddressModal .modal-body .form-icon {
    position: absolute;
    top: 10px;
    left: 15px;
    color: #a5a5a5;
}

.wx_AddAddressModal .modal-content {
    border-radius: 30px;
    padding: 10px;
}

.wx_AddAddressModal .modal-body .select-box__list li label {
    padding-left: 20px;
}

@-webkit-keyframes HideList {
    from {
        transform: scaleY(1);
    }

    to {
        transform: scaleY(0);
    }
}

@keyframes HideList {
    from {
        transform: scaleY(1);
    }

    to {
        transform: scaleY(0);
    }
}

@media (min-width: 767px) {
    .justify-center-lg {
        justify-content: center;
    }

}

@media (min-width: 1200px) and (max-width: 1400px) {
    .heroimg {
        height: 462px;
        margin-bottom: -41px;

    }

    .container {
        max-width: 1200px !important;
    }

    .polygon {
        clip-path: polygon(156% -3%, -19% 100%, 100% 100%);
        margin-top: -214px;
    }
}

@media (min-width: 320px) and (max-width: 767px) {

    .wx_ReviewRatingBg {
        width: 100% !important;
    }

    .wx_ReviewRatingBgInner {
        margin-left: 0;
        margin-top: 3rem;
    }

    .wx_ReviewRatingBg .grid-item {
        width: 100%;
        padding: 15px;
        margin-bottom: 0;
    }

    .wx_RatingRightSec img:nth-child(2) {
        position: unset;
        width: 100%;
    }

    .wx_ReviewRatingBg {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 20px;
    }

    .navbar-toggler {
        color: var(--default-orange) !important;
        border: none !important;
    }

    .navbar-toggler:focus {
        box-shadow: none;
    }

    #headercollpase .nav-item {
        padding: 0px 5px;
        width: 100%;
        border-bottom: 1px #ddd dashed;
    }

    #headercollpase .nav-item:last-child {
        width: 130px;
        border-bottom: none;
        margin-bottom: 0px;
    }

    #headercollpase .btn-hero {
        margin-bottom: 0px;
    }

    .nav-item {
        padding-left: 0px;
    }

    .nav-item.active .nav-link::before {
        left: 0px;
    }

    .polygonfooter {
        clip-path: polygon(115% 48%, -1% 100%, 104% 100%);
        margin-bottom: -1px;
    }

    .left-banner-content h2 {
        font-size: 36px;
        line-height: 40px;
    }

    .left-banner-content h3 {
        font-size: 36px;
    }

    .btn-hero {
        padding: 8px 80px !important;
        margin-bottom: 25px;
    }

    .heroimg {
        margin-bottom: -100px;
    }

    .footercopyright {
        font-size: 12px;
    }

    .googleplay,
    .applestore {
        font-size: 15px;
        padding: 8px 19px;
    }

    .googleplay i,
    .applestore i {
        font-size: 15px;
    }

    .footer-container {
        padding: 7px;
        background-color: var(--default-blue) !important;
        margin: 0px;
        margin-top: 0px;
    }

    .footer-logo-small {
        padding: 10px;
        margin-top: 50px;
        text-align: center;
        margin-bottom: 50px;
    }

    .policty li a {
        font-size: 12px;
    }

    .customfooter {
        padding-top: 25PX !important;
        border-radius: 0px !important;
    }

    .subscribeInputContainer {
        padding: 6px;
    }

    .spaceContainer {
        padding: 45px 15px;
    }

    .section-title h2 {
        font-size: 36px;
        line-height: 40px;
    }

    .herosection {
        padding: 0px 15px;
        border-radius: 0px;
    }

    .p-90 {
        padding: 100px 14px;
    }

    .polygon,
    .polygonfooter {
        display: none;
    }

    .heroimg {
        width: 100%;
        height: 100%;
    }

    .mw-360px,
    .gmail-btn,
    .title,
    .btn-hero {
        width: 100%;
    }

    .cta {
        padding: 20px 28px !important;
        border-radius: 0px;
    }

    .cta-phone {

        padding-right: 0px !important;
        padding-left: 0px !important;
    }

    .cta h4 {
        font-size: 22px;
    }

    h1 {
        font-size: 37px;
        line-height: 44px;
    }

    p {
        font-size: 18px;
        line-height: 26px;
    }

    .section-title h2:after {
        width: 50%;
        margin: 0 auto;
    }

    .navbar-nav {
        align-items: baseline;
    }

    .opt-box ul li {
        font-size: 15px;
        width: 34px;
        height: 34px;
        line-height: 40px;
    }

    .myform {
        padding: 2rem;
    }

    .mylogin-form h3 {
        font-size: 30px;
    }

    .mylogin-form h2 {
        font-size: 36px;
        line-height: 20px;
    }

    .mylogin-form h3 {
        margin-top: 25px;
        margin-bottom: 20px;
    }

    .login-btn {
        margin-top: 0px;
    }

    .mylogin-form p {
        margin-bottom: 0px !important;
    }

    .recive-code {
        margin-top: 50px;
        margin-bottom: 20px;
    }

    .trading ul li {
        margin: 8px 2px;
    }

    .profile-right-col {
        padding-left: 0px;
    }

    .left-login-bottom {
        margin-top: 50px;
    }

    .page-title-breadcrumb {
        display: block;
    }

    .article-author {
        display: block;
    }

    .article-author-left {
        text-align: center;
        margin-bottom: 20px;
    }

    .article-author-right {
        text-align: center;
    }

    .comments-wrap ul li {
        border-bottom: 1px #ddd solid;
        padding: 25px 0;
        margin-left: 25px;
    }

    .page-title-breadcrumb h2 {
        margin-bottom: 20px;
    }

    .pagination-col ul.pagination li.active span.page-link {
        width: 40px;
        height: 40px;
        font-size: 15px;
        line-height: 30px;
    }

    .pagination-col ul.pagination li a {
        width: 40px;
        height: 40px;
        font-size: 15px;
        line-height: 30px;
    }

    .pagination-col ul.pagination li span.page-link {
        width: 40px;
        height: 40px;
        font-size: 15px;
        line-height: 30px;
    }

    .pagination-col ul.pagination li span img {
        width: 20px;
    }

    .pagination-col ul.pagination li a img {
        width: 20px;
    }

    @media (min-width: 320px) and (max-width: 900px) {
        .policty li {
            border: none !important;
        }

        .container {
            min-width: 100% !important;
        }

        .policty li i {
            font-size: 7px;
        }

        .wx_myprofileBgMain {
            position: unset;
            height: auto;
        }

    }


}

@media (min-width: 320px) and (max-width: 900px) {
    .policty li {
        border: none !important;
    }

    .container {
        min-width: 100% !important;
    }

    .policty li i {
        font-size: 7px;
    }

    .wx_myprofileBgMain {
        position: unset;
        height: auto;
    }

}

input#session-date {
    position: relative;
    overflow: hidden;
}

input#session-date::-webkit-calendar-picker-indicator {
    display: block;
    top: 0;
    left: 0;
    background: #0000;
    position: absolute;
    transform: scale(50)
}

.pac-logo .pac-item {
    padding: 10px 20px;
    cursor: pointer;
}

.pac-logo:after {
    display: none;
}

.pac-logo {
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(112, 144, 176, 0.2);
    z-index: 9999 !important;
}

.wx_loader {
    position: absolute;
    z-index: 9999;
    top: 85%;
    left: 50%;
    /* transform:translate(-50%,-50%) */
    height: 100vh;
}


/* 404 Error Page */

.wx_errorBg img {
    margin: 2rem auto;
    width: 40%;
    display: flex;
}

.wx_errorBg p {
    color: #4A4F61;
}

.wx_errorBg a {
    background-color: var(--default-orange);
    border: 1px solid var(--default-orange);
    transition: 0.3s;
    color: #fff;
    display: inline-block;
    padding: 10px 20px;
    margin: 0 auto;
}

.wx_errorBg a:hover,
.wx_errorBg a:focus {
    background-color: #fff;
    border: 1px solid var(--default-orange);
    color: var(--default-orange);
}

.wx_errorBg h2 {
    display: inline-block;
    position: relative;
}

.wx_errorBg h2:before {
    content: '';
    display: block;
    bottom: 10px;
    position: absolute;
    width: 100%;
    opacity: 0.2;
    border: 8px solid #FF4200;
}

.wx_errorFooterBg {
    margin-top: 4rem;
    margin-bottom: 0.5rem;
    padding-top: 10px;
    border-top: 1px solid rgba(25, 25, 25, 20%);
}

.wx_errorFooterBg p {
    color: #191919;
    opacity: 0.5;
    font-size: 22px;
}

.wx_errorFooterBg a {
    color: #191919;
    text-decoration: none;
    font-weight: 600;
}

/* 25/04/2023 */
.wx_selectAddressModal .form-check-input {
    background: transparent;
}

.wx_selectAddressModal .form-check-input[type=radio] {
    border: 1px solid #191919;
}

.wx_selectAddressModal .form-check-input:checked {
    background-color: #FF4200 !important;
    border-color: #FF4200 !important;
    box-shadow: none;
}

.wx_selectAddressModal .card {
    background: #F5F5FB;
    border-radius: 10px;
    border: 0;
}

.wx_selectAddressModal .modal-content {
    padding: 2rem;
    border-radius: 10px;
}

.wx_selectAddressModal .card h5 {
    font-weight: 400;
    font-size: 17px;
}

.wx_selectAddressModal .card p span:first-child {
    color: #111111;
    font-weight: 400;
    background: #FFFFFF;
    border-radius: 5px;
    font-size: 12px;
    padding: 1px 6px;
}

.wx_selectAddressModal .card p span:nth-child(2) {
    color: #ffffff;
    font-weight: 400;
    background: #767E87;
    border-radius: 5px;
    font-size: 14px;
    padding: 8px 15px;
    text-transform: uppercase;
}